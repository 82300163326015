

import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Routes, Route } from "react-router-dom";
import TemplateCore from "./Pages/template";
import moment from 'moment-timezone';


const CoreLogin = React.lazy(() => import("./Pages/Auth/login"));
const CoreForgotPassword = React.lazy(() => import("./Pages/Auth/forgot-password"));
const CoreUpdatePassword = React.lazy(() => import("./Pages/Auth/update-password"));
const CoreChangePassword = React.lazy(() => import("./Pages/Auth/change-password"));
const CoreProfile = React.lazy(() => import("./Pages/Auth/profile"));
const DashboardCore = React.lazy(() => import("./Pages/Dashboard"));
const CustomerCore = React.lazy(() => import("./Pages/Customers"));
const UsersCore = React.lazy(() => import("./Pages/Users"));
const ProductCore = React.lazy(() => import("./Pages/Products"));
const ModulesCore = React.lazy(() => import("./Pages/Users/Modules"));
const CustomItemCode = React.lazy(() => import("./Pages/Masters/CustomItemCode"));
const DepartmentCore = React.lazy(() => import("./Pages/Masters/Department"));
const RackingCore = React.lazy(() => import("./Pages/Masters/Racking"));
const OrdersCore = React.lazy(() => import("./Pages/Orders"));
const QuotationCore = React.lazy(() => import("./Pages/Quotation"));
// const OrderProcessingCore = React.lazy(() => import("./Pages/OrderProcessing"));
// const OrderTrackingCore = React.lazy(() => import("./Pages/OrderTracking"));
const OrderDesignersCore = React.lazy(() => import("./Pages/OrderDesignersManagement"));
const OrderQualityCheckingCore = React.lazy(() => import("./Pages/OrderQCManagement"));
const OrderProductionCore = React.lazy(() => import("./Pages/OrderProductionManagement"));
const ReportsCore = React.lazy(() => import("./Pages/Reports"));



function App() {
  useEffect(() => {
    moment.tz.setDefault('Australia/Sydney');
  }, []);

  return (
    <Routes>
      <Route path="/" element={<CoreLogin />} />
      <Route path="login" element={<React.Suspense fallback={<>...</>}><CoreLogin /></React.Suspense>} />
      <Route path="forgot-password" element={<React.Suspense fallback={<>...</>}><CoreForgotPassword /></React.Suspense>} />
      <Route path="update-password/:id" element={<React.Suspense fallback={<>...</>}><CoreUpdatePassword /></React.Suspense>} />
      <Route element={<TemplateCore />}>
        <Route path="change-password/*" element={<React.Suspense fallback={<>...</>}><CoreChangePassword /></React.Suspense>} />
        <Route path="profile/*" element={<React.Suspense fallback={<>...</>}><CoreProfile /></React.Suspense>} />
        <Route index path="dashboard/*" element={<React.Suspense fallback={<>...</>}><DashboardCore /></React.Suspense>} />
        <Route index path="customer/*" element={<React.Suspense fallback={<>...</>}><CustomerCore /></React.Suspense>} />
        <Route index path="users/*" element={<React.Suspense fallback={<>...</>}><UsersCore /></React.Suspense>} />
        <Route index path="materials/*" element={<React.Suspense fallback={<>...</>}><ProductCore /></React.Suspense>} />
        <Route index path="modules/*" element={<React.Suspense fallback={<>...</>}><ModulesCore /></React.Suspense>} />
        <Route index path="custom-item-code/*" element={<React.Suspense fallback={<>...</>}><CustomItemCode /></React.Suspense>} />
        <Route index path="departments/*" element={<React.Suspense fallback={<>...</>}><DepartmentCore /></React.Suspense>} />
        <Route index path="racking/*" element={<React.Suspense fallback={<>...</>}><RackingCore /></React.Suspense>} />
        <Route index path="orders/*" element={<React.Suspense fallback={<>...</>}><OrdersCore /></React.Suspense>} />
        <Route index path="quotation/*" element={<React.Suspense fallback={<>...</>}><QuotationCore /></React.Suspense>} />
        {/* <Route index path="order-tracking/*" element={<React.Suspense fallback={<>...</>}><OrderTrackingCore /></React.Suspense>} /> */}
        {/* <Route index path="order-processing/*" element={<React.Suspense fallback={<>...</>}><OrderProcessingCore /></React.Suspense>} /> */}
        <Route index path="designers/*" element={<React.Suspense fallback={<>...</>}><OrderDesignersCore /></React.Suspense>} />
        <Route index path="quality-checking/*" element={<React.Suspense fallback={<>...</>}><OrderQualityCheckingCore /></React.Suspense>} />
        <Route index path="production/*" element={<React.Suspense fallback={<>...</>}><OrderProductionCore /></React.Suspense>} />
        <Route index path="reports/*" element={<React.Suspense fallback={<>...</>}><ReportsCore /></React.Suspense>} />
      </Route>
    </Routes>
  );
}

export default App;
