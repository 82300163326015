import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { MyDiv, PTag } from "./Components";
import Avatar from "react-avatar";
import { RiUserHeartLine, RiLockPasswordLine, RiLogoutCircleLine } from "react-icons/ri";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
import swal from "sweetalert2";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function Header() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const loadUser = useCallback(async (data) => {
    await sleep(1000);
    var UserId = localStorage.getItem('userId');
    var Token = localStorage.getItem('token');
    var url = API_BASE_URL + `fetch-specific-profile/` + UserId;

    try {
      const response = await axios.get(url, {
        headers: {
          'x-access-token': `${Token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      setPosts(response.data);
    } catch (err) {
      swal.fire({
        text: err.response.data,
        icon: "error",
        type: "error"
      });

      if (err.response.data === "Invalid Token") {
        window.location.href = "/login";
      } else {
        // Handle other error scenarios
      }
    }
  }, []);

  useEffect(() => {
    if ((localStorage.getItem("token") === null) && (localStorage.getItem("role") === null)) {
      navigate("/login");
    } else {
      async function init() {
        const data = await localStorage.getItem('userId');
        loadUser(data);
      }
      init();
    }
  }, [loadUser, navigate]);

  const logoutCore = () => {
    window.location.href = "/login";
  }

  return (
    <>
      <header>
        <MyDiv className="row">
          <MyDiv className="col-md-4 col-6 d-flex align-items-center">
            <Link to="/dashboard">
              <img src={require('../../Assets/images/EncoreSheetMetalLogo.png')} alt="logo" />
            </Link>
          </MyDiv>
          {posts?.length ? posts.map((item) => (
            <MyDiv className="col-md-8 text-end right-menu col-6" key={item}>
              <MyDiv className="HeaderInfo hide-for-small"><span>{new Date().toLocaleString()}</span></MyDiv>
              <Avatar round="50px" size="40" name={item.user_firstName} src="" />
              <MyDiv className="ProfileArea">
                <DropdownButton title={[item.user_firstName, " ", item.user_lastName]}>
                  <Dropdown.Item as={Link} to="/change-password"><RiLockPasswordLine /> Change Password</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/profile"><RiUserHeartLine /> My Profile</Dropdown.Item>
                  <Dropdown.Item onClick={logoutCore}><RiLogoutCircleLine /> Logout</Dropdown.Item>
                </DropdownButton>
                <PTag className="hide-for-small">{item.user_Designation}</PTag>
              </MyDiv>
            </MyDiv>
          )) : ''}
        </MyDiv>
      </header>
    </>
  );
}

export default Header;
