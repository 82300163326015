import React from 'react';
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from 'react-pro-sidebar';
import { Outlet, Link, useLocation } from "react-router-dom";
import Header from "./Common/header";
import { MyDiv, PTag } from "./Common/Components";
import { AiFillDashboard, AiFillFileText } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BiMenu } from "react-icons/bi";
import { BsCart4 } from "react-icons/bs";
import { GiRadarCrossSection, GiServerRack } from "react-icons/gi";
import { CgStack } from "react-icons/cg";
import { MdOutlineAutoGraph } from "react-icons/md";
import { SiAltiumdesigner } from "react-icons/si";
import { LiaIndustrySolid } from "react-icons/lia";
import { FaRegObjectGroup, FaSpellCheck } from "react-icons/fa";
import { RiUserSettingsLine, RiStackFill } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { BsPatchQuestion } from "react-icons/bs";

function TemplateCore() {

    const { collapseSidebar } = useProSidebar();
    const pathname = useLocation().pathname;
    var RolePermission = JSON.parse(localStorage.getItem('role'));


    return (
        <div>
            <Header />
            <MyDiv className="GeneralContainer">
                <MyDiv className="LeftSideContainer">
                    <Button className="SidebarCollapse" onClick={() => collapseSidebar()}>
                        <BiMenu />
                    </Button>
                    <Sidebar>
                        <Menu>
                            <MenuItem title="Dashboard" className={`${pathname === '/dashboard' ? "menuActive" : ""}`} icon={<AiFillDashboard />} component={<Link to="/dashboard" />}> Dashboard</MenuItem>
                            {RolePermission && RolePermission.Product && RolePermission.Product.view === "1" ?
                                <MenuItem title="Manage Materials" className={`${pathname.startsWith('/materials') ? "menuActive" : ""}`} icon={<RiStackFill />} component={<Link to="/materials" />}>Manage Materials </MenuItem>
                                : ""}
                            {RolePermission && RolePermission.Customer && RolePermission.Customer.view === "1" ?
                                <MenuItem title="Manage Customer" className={`${pathname.startsWith('/customer') ? "menuActive" : ""}`} icon={<HiUserGroup />} component={<Link to="/customer" />}>Manage Customer </MenuItem>
                                : ""}
                            <SubMenu label="Manage Quotation" icon={<BsPatchQuestion />} className={`${pathname.startsWith('/quotation/master') || pathname.startsWith('/quotation/designers') || pathname.startsWith('/quotation/quality-checking') ? "menuActive" : ""}`}>
                                {RolePermission && RolePermission.OrderManagement && RolePermission.OrderManagement.view === "1" ? <>
                                    <MenuItem title="quotation" className={`${pathname.startsWith('/quotation/master') || pathname === '/quotation/' ? "menuActive submenuItem" : "submenuItem"}`} icon={<CgStack />} component={<Link to="/quotation/master" />}>Quotation Master </MenuItem>
                                </>
                                    : ""}
                                    {RolePermission && RolePermission.DesignerModule && RolePermission.DesignerModule.view === "1" ? <>
                                    <MenuItem title="Designers Management" className={`${pathname.startsWith('/quotation/designers') || pathname === '/quotation/designers' ? "menuActive submenuItem" : "submenuItem"}`} icon={<SiAltiumdesigner />} component={<Link to="/quotation/designers" />}>Designers Management </MenuItem>
                                    <MenuItem title="QC Management" className={`${pathname.startsWith('/quotation/quality-checking') || pathname === '/quotation/quality-checking' ? "menuActive submenuItem" : "submenuItem"}`} icon={<FaSpellCheck />} component={<Link to="/quotation/quality-checking" />}>QC Management </MenuItem>
                                </>
                                    : ""}
                            </SubMenu>
                            <SubMenu label="Manage Orders" icon={<BsCart4 />} className={`${pathname.startsWith('/orders') || pathname.startsWith('/designers') || pathname.startsWith('/quality-checking') || pathname.startsWith('/production') ? "menuActive" : ""}`}>
                                {RolePermission && RolePermission.OrderManagement && RolePermission.OrderManagement.view === "1" ? <>
                                    <MenuItem title="Orders" className={`${pathname.startsWith('/orders') || pathname === '/orders' ? "menuActive submenuItem" : "submenuItem"}`} icon={<CgStack />} component={<Link to="/orders" />}> Master Orders  </MenuItem>
                                </>
                                    : ""}
                                {/* <MenuItem title="Order Processing" className={`${pathname === '/order-processing' ? "menuActive" : ""}`} icon={<RiLuggageCartFill />} component={<Link to="/order-processing" />}>Order Processing </MenuItem> */}
                                {RolePermission && RolePermission.DesignerModule && RolePermission.DesignerModule.view === "1" ? <>
                                    <MenuItem title="Designers Management" className={`${pathname.startsWith('/designers') || pathname === '/designers' ? "menuActive submenuItem" : "submenuItem"}`} icon={<SiAltiumdesigner />} component={<Link to="/designers" />}>Designers Management </MenuItem>
                                    <MenuItem title="QC Management" className={`${pathname.startsWith('/quality-checking') || pathname === '/quality-checking' ? "menuActive submenuItem" : "submenuItem"}`} icon={<FaSpellCheck />} component={<Link to="/quality-checking" />}>QC Management </MenuItem>
                                </>
                                    : ""}
                                {RolePermission && RolePermission.Production && RolePermission.Production.view === "1" ? <>
                                    <MenuItem title="Production Management" className={`${pathname.startsWith('/production') || pathname === '/production' ? "menuActive submenuItem" : "submenuItem"}`} icon={<LiaIndustrySolid />} component={<Link to="/production" />}>Production Management </MenuItem>
                                    
                                </> : ""}
                                {/* <MenuItem title="Order Tracking" className={`${pathname === '/order-tracking' ? "menuActive" : ""}`} icon={<MdFindReplace />} component={<Link to="/order-tracking" />}>Order Tracking </MenuItem> */}
                            </SubMenu>
                            <SubMenu label="Reports" icon={<MdOutlineAutoGraph />} className={`${pathname.startsWith('/reports') || pathname.startsWith('/reports/designer-reports') || pathname.startsWith('/reports/qc-reports') || pathname.startsWith('/reports/production-reports') || pathname.startsWith('/reports/loading-reports') || pathname.startsWith('/reports/order-reports') || pathname.startsWith('/reports/qc-reports') || pathname.startsWith('/reports/production-reports') || pathname.startsWith('/reports/customer-reports') ? "menuActive" : ""}`}>
                                {RolePermission && RolePermission.Master && RolePermission.DesignReport.view === "1" ? <> <MenuItem title="Designers Report" className={`${pathname === '/reports/designer-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/designer-reports" />}>Designer Reports </MenuItem>
                                    <MenuItem title="QC Report" className={`${pathname.startsWith('/reports/qc-reports') || pathname === '/reports/qc-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/qc-reports" />}>QC Reports </MenuItem> </> : ""}
                                {RolePermission && RolePermission.Master && RolePermission.ProductionReport.view === "1" ? <MenuItem title="Production Emp Report" className={`${pathname === '/reports/production-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/production-reports" />}>Production Emp Reports </MenuItem> : ""}
                                {RolePermission && RolePermission.Master && RolePermission.LoadingReport.view === "1" ? <MenuItem title="Loading Report" className={`${pathname === '/reports/loading-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/loading-reports" />}>Loading Reports </MenuItem> : ""}
                                {RolePermission && RolePermission.Master && RolePermission.CustomerReport.view === "1" ? <MenuItem title="Customer Report" className={`${pathname === '/reports/customer-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/customer-reports" />}>Customer Reports </MenuItem> : ""}
                                {RolePermission && RolePermission.Master && RolePermission.OrderReport.view === "1" ? <MenuItem title="Order Report" className={`${pathname === '/reports/order-reports' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/order-reports" />}>Order Reports </MenuItem> : ""}
                                 <MenuItem title="Rack Management" className={`${pathname === '/reports/rack-management' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/rack-management" />}>Rack Management</MenuItem> 
                                 {RolePermission && RolePermission.Production && RolePermission.Production.view === "1" ? <>
                                    
                                    <MenuItem title="Production Emp Work Info" className={`${pathname === '/reports/production-emp-work-info' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/reports/production-emp-work-info" />}>Prod Emp Work Info</MenuItem> 
                                </> : ""}
                            </SubMenu>
                            {RolePermission && RolePermission.UserManagement && RolePermission.UserManagement.view === "1" ?
                                <SubMenu label="Users" icon={<RiUserSettingsLine />} className={`${pathname.startsWith('/users') || pathname.startsWith('/modules') ? "menuActive" : ""}`}>
                                    <MenuItem title="Manage Users" className={`${pathname === '/users' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/users" />}>Manage Users </MenuItem>
                                    <MenuItem title="Manage Roles" className={`${pathname === '/users/roles' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/users/roles" />}>Manage Roles </MenuItem>
                                    {/* <MenuItem title="Manage Modules" className={`${pathname === '/modules' ? "menuActive submenuItem" : "submenuItem"}`} component={<Link to="/modules" />}>Manage Modules </MenuItem> */}
                                </SubMenu> : ""}
                            {RolePermission && RolePermission.Master && RolePermission.Master.view === "1" ?
                                <SubMenu label="Masters" icon={<FaRegObjectGroup />} className={`${pathname.startsWith('/custom-item-code') || pathname.startsWith('/departments') || pathname.startsWith('/racking') ? "menuActive" : ""}`}>
                                    <MenuItem title="Manage Custom Orders" className={`${pathname === '/custom-item-code' ? "menuActive submenuItem" : "submenuItem"}`} icon={<AiFillFileText />} component={<Link to="/custom-item-code" />}>Custom Item Code </MenuItem>
                                    {/* <MenuItem title="Departments" className={`${pathname.startsWith('/departments') || pathname === '/departments' ? "menuActive submenuItem" : "submenuItem"}`} icon={<GiRadarCrossSection />} component={<Link to="/departments" />}>Custom Departments </MenuItem> */}
                                    <MenuItem title="Racking" className={`${pathname === '/racking' ? "menuActive submenuItem" : "submenuItem"}`} icon={<GiServerRack />} component={<Link to="/racking" />}>Racking </MenuItem>
                                </SubMenu>
                                : ""}
                        </Menu>
                    </Sidebar>
                </MyDiv>
                <MyDiv className="RightMainContainer">
                    <MyDiv className="Right-Side-Main-Container">
                        <Outlet />
                    </MyDiv>
                    <MyDiv className="footer-copyrights ">
                        <PTag className="text-center mt-15">{(new Date().getFullYear())} @ ENCORE. All rights reserved</PTag>
                    </MyDiv>
                </MyDiv>
            </MyDiv>
        </div>
    );
}

export default TemplateCore;