import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './masterOrderSlice';
import quotationReducer from './quotationSlice';
import designReportReducer from './designReportSlice';
import qcReportReducer from './qcReportSlice';
import orderReportReducer from './orderReportSlice';

const store = configureStore({
  reducer: {
    orders: orderReducer,
    quotation: quotationReducer,
    designReport: designReportReducer,
    qcReport: qcReportReducer,
    orderReport: orderReportReducer,
  },
});

export default store;
