import React from 'react';

function BaseTag({ tag, ...props }) {
  return React.createElement(tag, props, props.children);
}

export function MyDiv(props) {
  return <BaseTag tag="div" {...props} />;
}

export function PTag(props) {
  return <BaseTag tag="p" {...props} />;
}

export function LabelTag(props) {
  return <BaseTag tag="label" {...props} />;
}

export function SpanTag(props) {
  return <BaseTag tag="span" {...props} />;
}

export function StrongTag(props) {
  return <BaseTag tag="strong" {...props} />;
}

export function HeadingOne(props) {
  return <BaseTag tag="h1" {...props} />;
}

export function HeadingTwo(props) {
  return <BaseTag tag="h2" {...props} />;
}

export function HeadingThree(props) {
  return <BaseTag tag="h3" {...props} />;
}

export function HeadingFour(props) {
  return <BaseTag tag="h4" {...props} />;
}

export function HeadingFive(props) {
  return <BaseTag tag="h5" {...props} />;
}

export function HeadingSix(props) {
  return <BaseTag tag="h6" {...props} />;
}
